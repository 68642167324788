<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="from_date">From Date:</label>
            <input class="form-control" required type="date" v-model="from_date">
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="to_date">To Date:</label>
            <input class="form-control" required type="date" v-model="to_date">
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="road_warrants" :columns="columns" :title="'Road Warrants'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('road_warrants', ['road_warrants', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        let data = this.first_table
        data.push({name: 'action', text: 'Action',
          raw: {
            type: 'Action',
            fields: [
              getCapability('edit_road_warrant', this.user) ? {event: 'edit_road_warrant', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
              getCapability('delete_road_warrant', this.user) ? {event: 'road_warrants/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
            ]
          }
        })
        return data
      }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
        from_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        to_date: moment().add(1, 'days').format("YYYY-MM-DD"),
        first_table :[
          {name: 'road_warrant_date', text: 'Date', order: 'desc', raw:{type:'Moment_date'}},
          {name: 'road_warrant_code', text: 'Road Warrant Code'},
          {name: 'vehicle', text: 'Vehicle',
            raw: {
              type: 'Sub',
              value: 'vehicle_no'
            }
          },
          {name: 'vehicle', text: 'Vehicle Code',
            raw: {
              type: 'Sub',
              value: 'vehicle_code'
            }
          },
          {name: 'driver', text: 'Driver',
            raw: {
              type: 'Sub',
              value: 'name'
            }
          },
          {name: 'account', text: 'Account',
            raw: {
              type: 'Sub',
              value: 'account_name'
            }
          },
          {name: 'created_at', text: 'Created', raw:{type:'Moment_UTC'}},
        ]
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('road_warrants', ['get_road_warrant_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id, from_date, to_date} = this
        let day = moment(from_date).diff(moment(to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }
        this.get_road_warrant_by_account({account_id, from_date, to_date})
      },
    },
    created() {
      this.$emit('onChildInit', 'Road Warrants', getCapability('add_road_warrant', this.user) ? '/road_warrants/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
    }
  }
</script>
