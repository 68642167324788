<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model="account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="vehicle_id">Vehicle:</label>
              <Select2 name="vehicle_id" v-model.number="vehicle_id" :options="vehicleOptions" />
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date">
            </div>
            <TimeComponent :label="'From Time'" :selected_time="from_time" v-on:selected-time="setStart"/>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" required type="date" v-model="to_date">
            </div>
            <TimeComponent :label="'To Time'" :selected_time="to_time" v-on:selected-time="setEnd"/>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="stops">Stop Duration:</label>
              <Select2 name="stops" v-model="stops" :options="stopOptions" />
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
        <label for="total_distance">Total Distance:</label>
        <input class="form-control text-right" disabled type="text" v-model="total_distance">
      </div>
      <div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
        <label for="total_duration">Total Duration:</label>
        <input class="form-control text-right" disabled type="text" v-model="total_duration">
      </div>
      <div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
        <label for="total_driving_time">Total Driving Time:</label>
        <input class="form-control text-right" disabled type="text" v-model="total_driving_time">
      </div>
      <div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
        <label for="total_idle_time">Total Idle Time:</label>
        <input class="form-control text-right" disabled type="text" v-model="total_idle_time">
      </div>
      <div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
        <label for="total_distance">Top Speed:</label>
        <input class="form-control text-right" disabled type="text" v-model="top_speed">
      </div>
      <div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
        <label for="total_driving_time">Average Speed:</label>
        <input class="form-control text-right" disabled type="text" v-model="average_speed">
      </div>
      <div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
        <label for="fuel_consumption_gps">Fuel Consumption:</label>
        <input class="form-control text-right" disabled type="text" v-model="fuel_consumption_gps">
      </div>
      <div v-if="journey_vehicle && journey_vehicle.length > 0" class="col-sm-3">
        <label for="fuel_price_gps">Fuel Cost:</label>
        <input class="form-control text-right" disabled type="text" v-model="fuel_price_gps">
      </div>
      <div class="col-sm-12 mt-3">
        <Datatable v-bind:entries="data_journey" :columns="columns" :currEntries="100" :title="title"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import TimeComponent from '../components/TimeComponent.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('journey_vehicles', ['journey_vehicle', 'onRequest']),
        title(){
          if(this.vehicle_id !== null){
            let veh = this.vehicles.filter(item => item.id === this.vehicle_id)
            if(veh.length > 0){
              return 'Journey Vehicle - ' + veh[0].vehicle_no + ' - ' + this.from_date + ' ' + this.from_time +  ' - ' + this.to_date + ' ' + this.to_time
            }
          }
          return 'Journey Vehicle'
        },
        data_journey(){
          return this.journey_vehicle
        }
    },
    data(){
      return {
        vehicle_id: null,
        account_id: null,
        from_date: moment().format("YYYY-MM-DD"),
        from_time: '00:00',
        to_date: moment().format("YYYY-MM-DD"),
        to_time: '23:59',
        total_distance: '',
        total_idle_time: '',
        total_driving_time: '',
        total_duration: '',
        stops: '180',
        top_speed: '',
        average_speed: '',
        fuel_consumption_gps: '',
        fuel_price_gps: '',
        vehicleOptions: [],
        accountOptions: [],
        stopOptions: [
          {id:'60', text:'1 min'},
          {id:'120', text:'2 min'},
          {id:'180', text:'3 min'},
          {id:'300', text:'5 min'},
          {id:'600', text:'10 min'},
          {id:'900', text:'15 min'},
          {id:'1200', text:'20 min'},
          {id:'1800', text:'30 min'},
          {id:'3600', text:'1 hr'},
          {id:'7200', text:'2 hr'},
          {id:'10800', text:'3 hr'},
          {id:'21600', text:'6 hr'},
        ],
        columns: [
          {name: 'start', text: 'Start Time', order: 'asc',
            raw: {
              type: 'Moment_UTC',
            }
          },
          {name: 'end', text: 'End Time',
            raw: {
              type: 'Moment_UTC',
            }
          },
          {name: 'start_location', text: 'Start Location'},
          {name: 'end_location', text: 'End Location'},
          {name: 'duration', text: 'Duration'},
          {name: 'distance', text: 'Distance'},
          {name: 'top_speed', text: 'Top Speed'},
          {name: 'average_speed', text: 'Average Speed'},
          {name: 'fuel_consumption_gps', text: 'Fuel Consumption (GPS)'},
          {name: 'fuel_price_gps', text: 'Fuel Cost (GPS)'},
        ]
      }
    },
    components: {
      Datatable,
      TimeComponent,
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {vehicle_id, from_date, from_time, to_date, to_time, stops} = this
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }

        // if(day < -3){
        //   this.error('Maximum Date Range is 3 days')
        //   return;
        // }
        let from_date_s = moment(from_date+ ' '+from_time).utc().format('YYYY-MM-DD')
        let from_time_s = moment(from_date+ ' '+from_time).utc().format('HH:mm')
        let to_date_s = moment(to_date+ ' '+to_time).utc().format('YYYY-MM-DD')
        let to_time_s = moment(to_date+ ' '+to_time).utc().format('HH:mm')

        this.get_data({vehicle_id, stops, from_date: from_date_s, from_time: from_time_s, to_date: to_date_s, to_time: to_time_s})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getRoutes: 'get_vehicle_by_account'}),
      ...mapActions('journey_vehicles', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.vehicle_id = null
      },
      setStart(val){
        this.from_time = val
      },
      setEnd(val){
        this.to_time = val
      },
      calculateTotalDuration(stopDuration, driveDuration) {
        // Convert stopDuration to seconds
        const stopParts = stopDuration.split(' ');
        let stopSeconds = 0;
        for (const part of stopParts) {
          if (part.endsWith('h')) {
            stopSeconds += parseInt(part.slice(0, -1)) * 3600;
          } else if (part.endsWith('min')) {
            stopSeconds += parseInt(part.slice(0, -3)) * 60;
          } else if (part.endsWith('s')) {
            stopSeconds += parseInt(part.slice(0, -1));
          }
        }

        // Convert driveDuration to seconds
        const driveParts = driveDuration.split(' ');
        let driveSeconds = 0;
        for (const part of driveParts) {
          if (part.endsWith('h')) {
            driveSeconds += parseInt(part.slice(0, -1)) * 3600;
          } else if (part.endsWith('min')) {
            driveSeconds += parseInt(part.slice(0, -3)) * 60;
          } else if (part.endsWith('s')) {
            driveSeconds += parseInt(part.slice(0, -1));
          }
        }

        // Calculate total duration in seconds
        const totalSeconds = stopSeconds + driveSeconds;

        // Format the total duration as h:min:s
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours}h ${minutes}min ${seconds}s`;
      },
    },
    created() {
      this.$emit('onChildInit', 'Report Journey Vehicle')
      this.clear_data()
      this.get_all()
    },
    watch: {
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      journey_vehicle(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.length > 0){
            let total_distance = 0
            let total_idle_time = 0
            let total_driving_time = 0
            let top_speed = 0
            let average_speed = 0
            let fuel_consumption_gps = 0
            let fuel_price_gps = 0
            nextState.map(item => {
              if(item.status == 4){
                total_distance = item.distance
                total_idle_time = item.stop_duration
                total_driving_time = item.drive_duration
                total_driving_time = item.drive_duration
                top_speed = item.top_speed
                average_speed = item.average_speed
                fuel_consumption_gps = item.fuel_consumption_gps
                fuel_price_gps = item.fuel_price_gps
                item.duration = this.calculateTotalDuration(total_driving_time, total_idle_time)
              }
            })
            this.top_speed = top_speed
            this.average_speed = average_speed
            this.total_distance = total_distance;
            this.total_idle_time = total_idle_time
            this.total_driving_time = total_driving_time
            this.fuel_consumption_gps = fuel_consumption_gps
            this.fuel_price_gps = fuel_price_gps
            
            this.total_duration = this.calculateTotalDuration(total_driving_time, total_idle_time)
          }
        }
      }
    }
  }
</script>
