<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-12">
        <Datatable v-bind:entries="expired_license" :columns="columns" :currEntries="100" :title="title"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('expired_licenses', ['expired_license', 'onRequest']),
        title(){
          if(this.account_id !== ''){
            let acc = this.accounts.filter(item => item.id === this.account_id)
            if(acc.length > 0){
              return 'Expired License - ' + acc[0].account_name
            }
          }
          return 'Expired License'
        }
    },
    data(){
      return {
        account_id: '',
        columns: [
          {name: 'expired_date', text: 'Expired Date', raw: {
            type: 'Moment_date_expiring',
          }, order: 'asc'},
          {name: 'license_name', text: 'License Name'},
          {name: 'license_number', text: 'License Number'},
          {name: 'asset_name', text: 'Asset'},
        ],
        accountOptions: [],
      }
    },
    components: {
      Datatable,
      Select2,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id} = this

        this.get_data({account_id})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('expired_licenses', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Report Expiring Licenses')
      this.clear_data()
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
