<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date">
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" required type="date" v-model="to_date">
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-12">
        <Datatable v-bind:entries="punctuality" :columns="columns" :currEntries="100" :title="title"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('punctualities', ['punctuality', 'onRequest']),
        title(){
          if(this.account_id !== ''){
            let acc = this.accounts.filter(item => item.id === this.account_id)
            if(acc.length > 0){
              return 'Trip Punctuality - ' + acc[0].account_name+ ' - ' + this.from_date +  ' - ' + this.to_date
            }
          }
          return 'Trip Punctuality'
        }
    },
    data(){
      return {
        account_id: '',
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        columns: [
          {name: 'route_name', text: 'Route Name', order: 'desc'},
          {name: 'total', text: 'Total Trip', class: 'text-right'},
          {name: 'early_start', text: 'Early Start', class: 'text-right'},
          {name: 'on_time_start', text: 'On Time Start', class: 'text-right'},
          {name: 'late_start', text: 'Late Start', class: 'text-right'},
          {name: 'compliance_rate_start', text: 'Compliance Rate Start', class: 'text-right'},
          {name: 'early_finish', text: 'Early Finish', class: 'text-right'},
          {name: 'on_time_finish', text: 'On Time Finish', class: 'text-right'},
          {name: 'late_finish', text: 'Late Finish', class: 'text-right'},
          {name: 'compliance_rate_finish', text: 'Compliance Rate Finish', class: 'text-right'},
        ],
        accountOptions: [],
      }
    },
    components: {
      Datatable,
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id, from_date, to_date} = this
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }

        this.get_data({account_id, from_date, to_date})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('punctualities', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Report Trip Punctuality')
      this.clear_data()
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
