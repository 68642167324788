<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="payment_link.account" class="form-group col-sm-12">
        <label for="parent_id">Account:</label>
        <input class="form-control" disabled name="account_id" type="text" v-model="payment_link.account.account_name">
      </div>
      <div class="form-group col-sm-12">
        <label for="payment_name">Payment Name:</label>
        <input class="form-control" name="payment_name" disabled type="text" v-model="payment_link.payment_name" maxlength="255">
      </div>
      <div class="form-group col-sm-12">
        <label for="sales">Sales:</label>
        <input class="form-control" name="sales" disabled type="text" :value="(payment_link.sales ? payment_link.sales.sales_name : '')" maxlength="255">
      </div>
      <div class="form-group col-sm-6">
        <label for="customer_first_name">Customer First Name:</label>
        <input class="form-control" name="customer_first_name" disabled type="text" v-model="payment_link.customer_first_name" maxlength="50">
      </div>
      <div class="form-group col-sm-6">
        <label for="customer_last_name">Customer Last Name:</label>
        <input class="form-control" name="customer_last_name" disabled type="text" v-model="payment_link.customer_last_name" maxlength="50">
      </div>
      <div class="form-group col-sm-6">
        <label for="customer_email">Customer Email:</label>
        <input class="form-control" name="customer_email" disabled type="email" v-model="payment_link.customer_email" maxlength="50">
      </div>
      <div class="form-group col-sm-6">
        <label for="customer_phone">Customer Phone:</label>
        <input class="form-control" name="customer_phone" disabled type="text" v-model="payment_link.customer_phone" maxlength="50">
      </div>
      <div class="form-group col-sm-12">
        <label for="notes">Note:</label>
        <textarea class="form-control" name="notes" disabled v-model="payment_link.notes" maxlength="255"></textarea>
      </div>
      <div class="form-group col-sm-4">
        <label for="usage_limit">Payment Link Usage Limit:</label>
        <input class="form-control" name="usage_limit" disabled type="number" v-model.number="payment_link.usage_limit">
      </div>
      <div class="form-group col-sm-4">
        <label for="expiry_duration">Expiration Duration:</label>
        <input class="form-control" name="expiry_duration" disabled type="number" v-model.number="payment_link.expiry_duration">
      </div>
      <div class="form-group col-sm-4">
        <label for="expiry_unit">Expiration Time Unit:</label>        
        <select class="form-control" disabled name="expiry_unit" v-model.number="payment_link.expiry_unit">
          <option value="days" style="color:black;">Day</option>
          <option value="hours" style="color:black;">Hour</option>
          <option value="minutes" style="color:black;">Minute</option>
        </select>
      </div>
      <div class="form-group col-sm-6">
        <label for="payment_url">Payment URL:</label>
        <input class="form-control" name="payment_url" disabled type="text" v-model="payment_link.payment_url">
      </div>
      <div class="form-group col-sm-6">
        <label for="gross_amount">Total Amount:</label>
        <input class="form-control" name="gross_amount" disabled type="text" v-model.number="gross_amount">
      </div>
      <div class="form-group col-sm-4">
        <label for="order_id">Order ID:</label>
        <input class="form-control" name="order_id" disabled type="text" v-model="payment_link.order_id">
      </div>
      <div v-if="payment_link.transaction_id" class="form-group col-sm-4">
        <label for="transaction_id">Transaction ID:</label>
        <input class="form-control" name="transaction_id" disabled type="text" v-model="payment_link.transaction_id">
      </div>
      <div v-if="payment_link.transaction_status" class="form-group col-sm-4">
        <label for="transaction_status">Transaction Status:</label>
        <input class="form-control" name="transaction_status" disabled type="text" v-model="payment_link.transaction_status">
      </div>
      <div v-if="payment_link.payment_type" class="form-group col-sm-4">
        <label for="payment_type">Payment Type:</label>
        <input class="form-control" name="payment_type" disabled type="text" v-model="payment_link.payment_type">
      </div>
      <div v-if="payment_link.store" class="form-group col-sm-4">
        <label for="store">Store:</label>
        <input class="form-control" name="store" disabled type="text" v-model="payment_link.store">
      </div>
      <div v-if="payment_link.bank" class="form-group col-sm-4">
        <label for="bank">Bank:</label>
        <input class="form-control" name="bank" disabled type="text" v-model="payment_link.bank">
      </div>
      <div v-if="payment_link.card_type" class="form-group col-sm-4">
        <label for="card_type">Card Type:</label>
        <input class="form-control" name="card_type" disabled type="text" v-model="payment_link.card_type">
      </div>
      <div v-if="payment_link.transaction_time" class="form-group col-sm-4">
        <label for="transaction_time">Transaction Time:</label>
        <input class="form-control" name="transaction_time" disabled type="text" v-model="transaction_time">
      </div>
      <div v-if="payment_link.settlement_time" class="form-group col-sm-4">
        <label for="settlement_time">Settlement Time:</label>
        <input class="form-control" name="settlement_time" disabled type="text" v-model="settlement_time">
      </div>
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12">
            <h4>Detail Payment</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in payment_link.payment_link_details" :key="index">
                <td>{{item.name}}</td>
                <td>{{formatNumber(item.price)}}</td>
                <td>{{item.quantity}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <router-link class="btn btn-default ml-1" to="/payment_links">OK</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  export default {
    computed: {
        ...mapState('payment_links', ['payment_link', 'onRequest']),
        gross_amount: {
          get(){
            if(this.payment_link && this.payment_link.gross_amount){
              return this.formatNumber(this.payment_link.gross_amount)
            }
            return null
          },
        },
        transaction_time: {
          get(){
            if(this.payment_link && this.payment_link.transaction_time){
              return moment(this.payment_link.transaction_time, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
            }
            return null
          },
        },
        settlement_time: {
          get(){
            if(this.payment_link && this.payment_link.settlement_time){
              return moment(this.payment_link.settlement_time, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
            }
            return null
          },
        },
    },
    methods: {
      formatNumber(value){
        return value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : value
      },
      ...mapActions('payment_links', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Payment Link')
      this.get(this.$route.params.id)
    },
  }
</script>
