<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="part_mileage.account" class="form-group col-sm-12">
        <label for="parent_id">Account:</label>
        <input class="form-control" disabled name="account_id" type="text" v-model="part_mileage.account.account_name">
      </div>
      <div v-if="part_mileage.vehicle" class="form-group col-sm-12">
        <label for="vehicle_id">Vehicle:</label>
        <input class="form-control" disabled name="vehicle_id" type="text" v-model="part_mileage.vehicle.vehicle_no">
      </div>
      <div v-if="part_mileage.part" class="form-group col-sm-12">
        <label for="part_id">Part:</label>
        <input class="form-control" disabled name="part_id" type="text" v-model="part_desc">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="initial_km">Initial Odometer KM:</label>
        <input class="form-control" name="initial_km" required type="number" v-model.number="part_mileage.initial_km" step="any" min="0">
      </div>
      <div class="form-group col-sm-6">
        <label for="reminder_km">Reminder Usage KM:</label>
        <input class="form-control" name="reminder_km" type="number" v-model.number="part_mileage.reminder_km" step="any" min="0">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="part_mileage.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/part_mileages">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('part_mileages', ['part_mileage', 'onRequest']),
        part_desc: {
          get(){
            if(this.part_mileage && this.part_mileage.part){
              return this.part_mileage.part.part_name + ' - ' + this.part_mileage.part.part_code
            }
            return null
          },
        }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.part_mileage)
      },
      ...mapActions('part_mileages', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit Part Mileage')
      this.get(this.$route.params.id)
    },
  }
</script>
