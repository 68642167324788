<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" required v-model="account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="vehicle_id">Vehicle:</label>
            <Select2 name="vehicle_id" required v-model.number="vehicle_id" :options="vehicleOptions" />
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <input type="file" @change="onChange" :disabled="account_id == null" ref="fileupload"/>
          </div>      
          <table class="table">
            <thead>
              <tr>
                <th>Date Time</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Speed</th>
                <th>Altitude</th>
                <th>Course</th>
                <th>Protocol</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="details in data" :key="details">
                <td>{{details.date}}</td>
                <td>{{details.latitude}}</td>
                <td>{{details.longitude}}</td>
                <td>{{details.speed}}</td>
                <td>{{details.altitude}}</td>
                <td>{{details.course}}</td>
                <td>{{details.protocol}}</td>
              </tr>
            </tbody>
          </table>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Insert" :disabled="this.onRequest">
            <input class="btn btn-secondary ml-1" value="Download Template" @click.prevent="download_template">
          </div>
        </div>
      </form>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Select2 from 'vue3-select2-component';
import * as XLSX from "xlsx"
import moment from 'moment'
export default {
  computed: {
      ...mapState('accounts', ['accounts']),
      ...mapState('vehicles', ['vehicles']),
      ...mapState('insert_positions', ['onRequest']),
  },
  data(){
    return {
      vehicle_id: null,
      account_id: null,
      vehicleOptions: [],
      accountOptions: [],
      data: [],
      file: null,
    }
  },
  methods: {
    handleSubmit(){
      if(this.onRequest) return;
      const {vehicle_id, data} = this
      if(data.length < 1 || vehicle_id == null){
        return;
      }
      let uplo = data.map(item => {
        let obj = {...item}
        //GPSWOX translate MPH to KMH
        obj.speed = (item.speed / 1.852).toFixed(2)
        //GPSWOX translate to UTC
        obj.date = moment.utc(moment(item.date)).format("YYYY-MM-DD HH:mm:ss")
        return obj
      })
      this.upload({vehicle_id, data:uplo})
      this.$refs.fileupload.value = null;
      this.vehicle_id = null
      this.file = null
      this.data = []
    },
    ...mapActions('accounts', ['get_all']),
    ...mapActions('vehicles', {getRoutes: 'get_vehicle_by_account'}),
    ...mapActions('insert_positions', ['upload']),
    ...mapActions('alert', ['error', 'clear']),
    onChangeAccount(event){
      this.getRoutes({account_id: parseInt(event.id)})
      this.vehicle_id = null
    },
    download_template(){
      window.open('/template_insert_position.xlsx')
    },
    onChange(event){
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();

        reader.onload = () => {
          this.arrayBuffer = reader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {
            type: "binary"
          });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          let res = XLSX.utils.sheet_to_json(worksheet, {
            raw: false
          })
          let arrs = []
          res.map(item => {
            if(!item["Date"] || item['Date'] === '' || !item["Time"] || item['Time'] === '' || !item["Latitude"] || item['Latitude'] === ''
            || !item["Longitude"] || item['Longitude'] === '' || !item["Speed"] || item['Speed'] === '' || !item["Altitude"] || item['Altitude'] === ''
            || !item["Course"] || item['Course'] === '' || !item["Protocol"] || item['Protocol'] === ''){
              return null;
            }
            let a = {date: '', latitude: '', longitude: '', speed: '', altitude: '', course: '', protocol: ''}
            a.date = item["Date"].trim() + " " + item["Time"].trim()
            a.latitude = item["Latitude"].trim()
            a.longitude = item["Longitude"].trim()
            a.speed = item["Speed"].trim()
            a.altitude = item["Altitude"].trim()
            a.course = item["Course"].trim()
            a.protocol = item["Protocol"].trim()
            arrs.push(a)
          })
          this.data = arrs
        }

        reader.readAsArrayBuffer(this.file);
      }
    },
  },
  created() {
    this.$emit('onChildInit', 'Insert Position GPS')
    this.get_all()
  },
  components: {
    Select2
  },
  watch: {
    vehicles(nextState, prevState){
      if(nextState !== prevState){
        this.vehicleOptions = []
        if(nextState.length > 0){
          nextState.map(item => {
            this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
          })
        }
        return
      }
    },
    accounts(nextState, prevState){
      if(nextState !== prevState){
        this.accountOptions = []
        if(nextState.length > 0){
          nextState.map(item => {
            this.accountOptions.push({id:item.id, text:item.account_name})
          })
        }
        return
      }
    },
  },
}
</script>
