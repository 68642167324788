<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model="account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="route_id">Route:</label>
              <Select2 name="route_id" v-model.number="route_id" :options="routeOptions" />
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="vehicle_id">Vehicle:</label>
              <Select2 name="vehicle_id" v-model.number="vehicle_id" :options="vehicleOptions" />
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date">
            </div>
            <TimeComponent :label="'From Time'" :selected_time="from_time" v-on:selected-time="setStart"/>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" required type="date" v-model="to_date">
            </div>
            <TimeComponent :label="'To Time'" :selected_time="to_time" v-on:selected-time="setEnd"/>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div v-for="summary in data_totals" :key="summary" class="col-sm-12">
        <div class="row">
          <div class="col-sm-3">
            <label>Speed Avg</label>
            <input class="form-control text-right" disabled type="text" :value="summary.speed_avg">
          </div>
          <div class="col-sm-3">
            <label>Speed Max</label>
            <input class="form-control text-right" disabled type="text" :value="summary.speed_max">
          </div>
          <div class="col-sm-3">
            <label>Duration</label>
            <input class="form-control text-right" disabled type="text" :value="summary.duration">
          </div>
          <div class="col-sm-3">
            <label>Distance</label>
            <input class="form-control text-right" disabled type="text" :value="summary.distance">
          </div>
        </div>
      </div>
      <div class="col-sm-12 mt-3">
        <Datatable v-bind:entries="data_geofence" :columns="columns" :currEntries="100" :title="title"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import TimeComponent from '../components/TimeComponent.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('routes', ['routes']),
        ...mapState('report_geofences', ['report_geofence', 'onRequest']),
        title(){
          if(this.vehicle_id !== null){
            let veh = this.vehicles.filter(item => item.id === this.vehicle_id)
            if(veh.length > 0){
              return 'Report Geofence - ' + veh[0].vehicle_no + ' - ' + this.from_date + ' ' + this.from_time +  ' - ' + this.to_date + ' ' + this.to_time
            }
          }
          return 'Report Geofence'
        },
    },
    data(){
      return {
        vehicle_id: null,
        route_id: null,
        account_id: null,
        from_date: moment().format("YYYY-MM-DD"),
        from_time: '00:00',
        to_date: moment().format("YYYY-MM-DD"),
        to_time: '23:59',
        vehicleOptions: [],
        accountOptions: [],
        routeOptions: [],
        columns: [
          {name: 'geofence_name', text: 'Stop Name'},
          {name: 'geofence_in', text: 'In', order: 'asc',
            raw: {
              type: 'Moment_UTC',
            }
          },
          {name: 'geofence_out', text: 'Out', 
            raw: {
              type: 'Moment_UTC',
            }
          },
          {name: 'duration_in', text: 'Duration'},
          {name: 'distance_in', text: 'Distance'},
          {name: 'duration_out', text: 'Duration Next Stop'},
          {name: 'distance_out', text: 'Distance Next Stop'},
          {name: 'speed_max', text: 'Top Speed'},
          {name: 'speed_avg', text: 'Average Speed'},
        ],
        data_geofence: [],
        data_totals: [],
      }
    },
    components: {
      Datatable,
      TimeComponent,
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {vehicle_id, from_date, from_time, to_date, to_time, route_id, account_id} = this
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }

        // if(day < -3){
        //   this.error('Maximum Date Range is 3 days')
        //   return;
        // }
        let from_date_s = moment(from_date+ ' '+from_time).utc().format('YYYY-MM-DD')
        let from_time_s = moment(from_date+ ' '+from_time).utc().format('HH:mm')
        let to_date_s = moment(to_date+ ' '+to_time).utc().format('YYYY-MM-DD')
        let to_time_s = moment(to_date+ ' '+to_time).utc().format('HH:mm')

        this.get_data({vehicle_id, route_id, account_id: Number(account_id), from_date: from_date_s, from_time: from_time_s, to_date: to_date_s, to_time: to_time_s})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
      ...mapActions('report_geofences', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getVehicles({account_id: parseInt(event.id)})
        this.getRoutes({account_id: parseInt(event.id)})
        this.account_id = event.id
        this.route_id = null
        this.vehicle_id = null
      },
      setStart(val){
        this.from_time = val
      },
      setEnd(val){
        this.to_time = val
      },
    },
    created() {
      this.$emit('onChildInit', 'Report Geofence')
      this.clear_data()
      this.get_all()
    },
    watch: {
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name +' - '+item.route_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      report_geofence(nextState, prevState){
        if(nextState !== prevState && nextState.rows){
          this.data_geofence = nextState.rows
          this.data_totals = nextState.totals
          return
        }
      },
    }
  }
</script>
