<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" v-model.number="part_mileage.account_id" :options="accountOptions"  @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="part_id">Part:</label>
        <Select2 name="part_id" v-model.number="part_mileage.part_id" :options="partOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="vehicle_id">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="part_mileage.vehicle_id" :options="vehicleOptions"  @change="onChangeVehicle($event)" @select="onChangeVehicle($event)"/>
      </div>
      <div class="form-group col-sm-6 required">
        <label for="initial_km">Initial Odometer KM:</label>
        <input class="form-control" name="initial_km" required type="number" v-model.number="part_mileage.initial_km" step="any" min="0">
      </div>
      <div class="form-group col-sm-6">
        <label for="reminder_km">Reminder Usage KM:</label>
        <input class="form-control" name="reminder_km" type="number" v-model.number="part_mileage.reminder_km" step="any" min="0">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="part_mileage.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/part_mileages">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('parts', ['parts']),
        ...mapState('part_mileages', ['onRequest']),
    },
    data(){
      return {
        part_mileage: {
          account_id: '',
          part_id: null,
          vehicle_id: null,
          initial_km: null,
          reminder_km: null,
          status: 1,
        },
        partOptions: [],
        vehicleOptions: [],
        accountOptions: [],
      }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.add(this.part_mileage)
      },
      ...mapActions('part_mileages', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', ['get_vehicle_by_account']),
      ...mapActions('parts', {getParts: 'get_all'}),
      onChange(event){
        this.get_vehicle_by_account({account_id: parseInt(event.id)})
        this.part_mileage.vehicle_id = null
        this.part_mileage.part_id = null
      },
      onChangeVehicle(event){
        let vehis = this.vehicles.filter(item => item.id === parseInt(event.id))
        if(vehis.length > 0){
          this.part_mileage.initial_km = vehis[0].odometer
        }
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Part Mileage')
      this.getParts()
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      parts(nextState, prevState){
        if(nextState !== prevState){
          this.partOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.partOptions.push({id:item.id, text:item.part_name + ' - ' + item.part_code})
            })
          }
          return
        }
      }
    }
  }
</script>
