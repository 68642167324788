<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date" :max="max_date">
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" required type="date" v-model="to_date" :max="max_date">
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>      
      <div class="col-sm-12 mt-3">
        <Datatable v-bind:entries="driver_ranking" :columns="columns" :currEntries="100"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('driver_rankings', ['driver_ranking', 'onRequest']),
    },
    data(){
      return {
        account_id: null,
        max_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        from_date: moment().startOf('month').format("YYYY-MM-DD"),
        to_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        accountOptions: [],
        columns: [
          {name: 'driver_name', text: 'Name'},
          {name: 'driver_code', text: 'Driver Code'},
          {name: 'total_hb', text: 'HB', raw: {type: 'format_number'}, class: 'text-right'},
          {name: 'total_ha', text: 'HA', raw: {type: 'format_number'}, class: 'text-right'},
          {name: 'total_sv', text: 'SV', raw: {type: 'format_number'}, class: 'text-right'},
          {name: 'total_sb', text: 'SB', raw: {type: 'format_number'}, class: 'text-right'},
          {name: 'total_distance', text: 'Distance (Km)', class: 'text-right', raw :{type: 'decimal_number', decimal: 2}},
          {name: 'pct_hb', text: '% HB', class: 'text-right', raw :{type: 'decimal_number_pct', decimal: 2}},
          {name: 'pct_ha', text: '% HA', class: 'text-right', raw :{type: 'decimal_number_pct', decimal: 2}},
          {name: 'pct_sv', text: '% SV', class: 'text-right', raw :{type: 'decimal_number_pct', decimal: 2}},
          {name: 'pct_sb', text: '% SB', class: 'text-right', raw :{type: 'decimal_number_pct', decimal: 2}},
          {name: 'score', text: 'Score', class: 'text-right', order: 'desc', raw :{type: 'decimal_number_pct', decimal: 1}},
        ]
      }
    },
    components: {
      Datatable,
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id, from_date, to_date} = this
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }

        this.get_data({account_id, from_date, to_date})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('driver_rankings', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Report Driver Ranking')
      this.clear_data()
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
