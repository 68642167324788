<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model="account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="vehicle_id">Vehicle:</label>
              <Select2 name="vehicle_id" v-model.number="vehicle_id" :options="vehicleOptions" />
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date">
            </div>
            <TimeComponent :label="'From Time'" :selected_time="from_time" v-on:selected-time="setStart"/>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" required type="date" v-model="to_date">
            </div>
            <TimeComponent :label="'To Time'" :selected_time="to_time" v-on:selected-time="setEnd"/>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>      
      <div class="col-sm-12 col-md-6 mt-3">
        <Datatable v-bind:entries="all_data" :columns="columns" :currEntries="100" :title="title" @onBtnClicked="onBtnClicked"/>
      </div>
      <div v-if="selected_img_url !== null" class="col-sm-12 col-md-6 mt-3">
        <img :src="selected_img_url" class="img-fluid"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  import TimeComponent from '../components/TimeComponent.vue'
  import moment from 'moment'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('report_cameras', ['report_camera', 'onRequest']),
        title(){
          if(this.vehicle_id !== null){
            let veh = this.vehicles.filter(item => item.id === this.vehicle_id)
            if(veh.length > 0){
              return 'Journey Vehicle - ' + veh[0].vehicle_no + ' - ' + this.from_date + ' ' + this.from_time +  ' - ' + this.to_date + ' ' + this.to_time
            }
          }
          return 'Journey Vehicle'
        },
    },
    data(){
      return {
        from_date: moment().format("YYYY-MM-DD"),
        from_time: '00:00',
        to_date: moment().format("YYYY-MM-DD"),
        to_time: '23:59',
        vehicle_id: null,
        account_id: null,
        selected_img_url: null,
        vehicleOptions: [],
        accountOptions: [],
        all_data: [],
        columns: [
          {name: 'created_at', text: 'Created At', order: 'desc',
            raw: {
              type: 'Moment_UTC',
            }
          },
          {name: 'camera', text: 'Camera'},
          {name: 'event', text: 'Event'},
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                {event: '', text: 'Open', method: 'raw_btn', class: 'btn btn-primary', check: {
                    name: 'event', value: null
                }},
              ]
            }
          },
        ],
      }
    },
    components: {
      Datatable,
      Select2,
      TimeComponent,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {vehicle_id, from_date, from_time, to_date, to_time} = this
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }

        // if(day < -3){
        //   this.error('Maximum Date Range is 3 days')
        //   return;
        // }
        let from_date_s = moment(from_date+ ' '+from_time).utc().format('YYYY-MM-DD')
        let from_time_s = moment(from_date+ ' '+from_time).utc().format('HH:mm')
        let to_date_s = moment(to_date+ ' '+to_time).utc().format('YYYY-MM-DD')
        let to_time_s = moment(to_date+ ' '+to_time).utc().format('HH:mm')

        this.get_data({vehicle_id, from_date: from_date_s, from_time: from_time_s, to_date: to_date_s, to_time: to_time_s})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getRoutes: 'get_vehicle_by_account'}),
      ...mapActions('report_cameras', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.vehicle_id = null
      },
      onBtnClicked(data){
        if(data.url){
          this.selected_img_url = data.url
        }
      },
      setStart(val){
        this.from_time = val
      },
      setEnd(val){
        this.to_time = val
      },
      getTime(time){
        if(time){
          return moment.utc(time, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
        }
        return "";
      },
    },
    created() {
      this.$emit('onChildInit', 'Report Camera')
      this.clear_data()
      this.get_all()
    },
    watch: {
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      report_camera(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.response_cameras && nextState.response_camera_events){
            let all_data = []
            nextState.response_cameras.map(item => {
              all_data.push({...item, ...{event: null}})
            })
            nextState.response_camera_events.map(item => {
              all_data.push({...item, ...{created_at: item.time}})
            })
            this.all_data = all_data
          }
          return
        }
      },
    }
  }
</script>
