<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model="account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="vehicle_id">Vehicle:</label>
              <Select2 name="vehicle_id" v-model.number="vehicle_id" :options="vehicleOptions" />
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date">
            </div>
            <TimeComponent :label="'From Time'" :selected_time="from_time" v-on:selected-time="setStart"/>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" required type="date" v-model="to_date">
            </div>
            <TimeComponent :label="'To Time'" :selected_time="to_time" v-on:selected-time="setEnd"/>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div v-for="summary in data_summary" :key="summary" class="col-sm-3">
        <label>{{summary.label}}</label>
        <input class="form-control text-right" disabled type="text" :value="summary.value">
      </div>
      <div class="col-sm-12 mt-3">
        <Datatable v-bind:entries="this.data_sensor" :columns="columns" :currEntries="100" :title="title"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import TimeComponent from '../components/TimeComponent.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('drivers', ['drivers']),
        ...mapState('history_sensors', ['history_sensor', 'onRequest']),
        title(){
          if(this.vehicle_id !== ''){
            let veh = this.vehicles.filter(item => item.id === this.vehicle_id)
            if(veh.length > 0){
              return 'History Sensor - ' + veh[0].vehicle_no + ' - ' + this.from_date + ' ' + this.from_time +  ' - ' + this.to_date + ' ' + this.to_time
            }
          }
          return 'History Sensor'
        }
    },
    data(){
      return {
        vehicle_id: '',
        account_id: '',
        from_date: moment().format("YYYY-MM-DD"),
        from_time: '00:00',
        to_date: moment().format("YYYY-MM-DD"),
        to_time: '23:59',
        columns: false,
        default_columns: [
          {name: 't', text: 'Time', order: 'desc',
              raw: {
                type: 'Moment_UTC',
              }
            },
          {name: 'lat', text: 'Latitude'},
          {name: 'lng', text: 'Longitude'},
          ],
        vehicleOptions: [],
        accountOptions: [],
        data_sensor: [],
        data_summary: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {vehicle_id, from_date, from_time, to_date, to_time} = this
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }
        this.data_summary = []

        // if(day < -3){
        //   this.error('Maximum Date Range is 3 days')
        //   return;
        // }
        let from_date_s = moment(from_date+ ' '+from_time).utc().format('YYYY-MM-DD')
        let from_time_s = moment(from_date+ ' '+from_time).utc().format('HH:mm')
        let to_date_s = moment(to_date+ ' '+to_time).utc().format('YYYY-MM-DD')
        let to_time_s = moment(to_date+ ' '+to_time).utc().format('HH:mm')

        this.get_data({vehicle_id, from_date: from_date_s, from_time: from_time_s, to_date: to_date_s, to_time: to_time_s})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      ...mapActions('history_sensors', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getVehicles({account_id: parseInt(event.id)})
        this.getDrivers({account_id: parseInt(event.id)})
        this.vehicle_id = ''
      },
      setStart(val){
        this.from_time = val
      },
      setEnd(val){
        this.to_time = val
      },
    },
    created() {
      this.$emit('onChildInit', 'Report History Sensor')
      this.clear_data()
      this.get_all()
    },
    components: {
      Datatable,
      TimeComponent,
      Select2,
    },
    watch: {
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      history_sensor (nextState, prevState) {
        if(nextState !== prevState){
          let driver_key = null
          if(nextState.sensors && nextState.sensors.length > 0){
            this.columns = [...this.default_columns]
            let sensor_keys = []
            nextState.sensors.map(item => {
              if(item.key !== 's' && item.key !== 'a'){
                if(item.name == 'driver'){
                  driver_key = item
                }
                this.columns.push({name: item.key, text: item.name})
                sensor_keys.push({name: item.key, text: item.name.split(' ')[0]})
              }
            })
            let data = []
            if(driver_key != null){
              nextState.data.map(item => {
                let obj = {...item}
                for(let i = 0;i<this.drivers.length;i++){
                  if(this.drivers[i].ibutton && this.drivers[i].ibutton.ibutton_no === obj[driver_key.key]){
                     obj[driver_key.key] = this.drivers[i].name
                     break
                  }
                }
                data.push(obj)
              })
            } else {
              data = nextState.data
            }
            nextState.sensor_data.map(item => {
              if(item.sensor && item.sensor.summary_columns){
                for(let i = 0;i<sensor_keys.length;i++){
                  if(sensor_keys[i].text == item.sensor.sensor_name){
                    let columns = item.sensor.summary_columns.split(',')
                    if(columns.length > 0){
                      let max = Number.MIN_VALUE
                      let min = Number.MAX_VALUE
                      let sum = 0
                      let isValid = true
                      if(columns.filter(i => i !== 'COUNT').length > 0){
                        for(let j =0;j<data.length;j++){
                          if(isNaN(data[j][sensor_keys[i].name.split(' ')[0]])){
                            isValid = false
                            break
                          }
                          let num = data[j][sensor_keys[i].name.split(' ')[0]]
                          max = Math.max(num, max)
                          min = Math.min(num, min)
                          sum += num
                        }
                      }
                      if(isValid){
                        for(let k = 0;k<columns.length;k++){
                          switch(columns[k]){
                            case 'MIN':
                              this.data_summary.push({label: 'Min ' + sensor_keys[i].text, value: min})
                              break;
                            case 'MAX':
                              this.data_summary.push({label: 'Max ' + sensor_keys[i].text, value: max})
                              break;
                            case 'SUM':
                              this.data_summary.push({label: 'Total ' + sensor_keys[i].text, value: sum})
                              break;
                            case 'COUNT':
                              this.data_summary.push({label: 'Count ' + sensor_keys[i].text, value: data.length})
                              break;
                            case 'AVG':
                              this.data_summary.push({label: 'Average ' + sensor_keys[i].text, value: (sum / data.length)})
                              break;
                          }
                        }
                      }
                    }
                  }
                }
              }
            })
            this.data_sensor = data
          }
          return
        }
      }
    },
  }
</script>
